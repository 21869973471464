
#input {
  margin: 30px 5% 30px 5%;
  width: 90%;

  top: 0;
  left: 0;
  cursor: pointer;


}
input[type=range]::-webkit-slider-runnable-track {
  height: 25px;
  cursor: pointer;
  background: #999999;
  border-radius: 20px;
  outline: none;
  overflow: hidden;
    box-shadow: inset 0 0 5px #000000;
}
#input::-webkit-slider-thumb {
  margin-top: -1px;

  border: 13px solid #590000;
  -webkit-appearance: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: -407px 0 0 400px #64e08e;
}


#output {
  font-family: "Bakbak One", sans-serif;
  font-size: 25px;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  position: absolute;
  top: 10px;
  left: 30px;
}

//*,
//*::before,
//*::after {
//  box-sizing: border-box;
//}
//
//$primary: #3e2caa;
//input[type='range'] {
//  -webkit-appearance: none;
//  -moz-appearance: none;
//  appearance: none;
//
//  &:focus {
//    outline: none;
//  }
//
//  &::-webkit-slider-thumb {
//    -webkit-appearance: none;
//  }
//
//  &::-moz-range-thumb {
//    border: none;
//  }
//}
//
//.range-style {
//  width: 400px;
//  height: 60px;
//  padding: 20px;
//  background: #4526B12D;
//  border-radius: 10px;
//  //box-shadow: -2px -2px 8px rgba(255, 255, 255, 0), 2px 2px 8px rgba(black, 0.5);
//
//  @mixin track-style {
//    display: flex;
//    align-items: center;
//    height: 20px;
//    border-radius: 10px;
//    box-shadow: inset -2px -2px 8px rgba(255, 255, 255, 0.57), inset 2px 2px 8px rgba(black, 0.5);
//  }
//
//  @mixin thumb-style {
//    position: relative;
//    top: -50%;
//    width: 40px;
//    height: 40px;
//    background-color: $primary;
//    background-image: linear-gradient(-45deg, rgba(white, 0.8), transparent);
//    border-radius: 50%;
//    box-shadow: -1px -1px 2px white, 1px 1px 2px rgba(black, 0.3);
//  }
//
//  &::-webkit-slider-runnable-track {
//    @include track-style;
//  }
//
//  &::-webkit-slider-thumb {
//    @include thumb-style;
//  }
//
//  &::-moz-range-track {
//    @include track-style;
//  }
//
//  &::-moz-range-thumb {
//    @include thumb-style;
//  }
//}
//
//.output {
//  width: 150px;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  font-family: "Gilroy-Bold";
//  font-size: 1.5rem;
//  background-color: #4526B12D;
//  margin-left: -10px;
//  border-radius: 0 5px 5px 0;
//}