@use '../../scss/' as *;
.footer {
  background: url('../../assets/images/background/bg-footer.jpg') center center no-repeat;
  background-size: cover;
  .footer-inner {
      padding: 61px 0 40px;
  }
  .widget {

    @include tablet {
        padding-right: 0 !important;
        padding-bottom: 0;
        margin-left: 0 !important;
    }
      &.widget-infor {
          padding-right: 30%;
          margin-top: 0;
          .logo {
              margin-bottom: 13px;
          }
          .content {
              font-size: 16px;
              line-height: 26px;
              color: #FFFFFF;
          }
          @include tablet {
            margin-bottom: 40px
          }
      }
      &.widget-menu {
          @include flexitem;
          .menu {
              width: 47%;
              ul  li {
                  padding-bottom: 6px;
                  a {
                      font-size: 14px;
                      line-height: 22px;
                      color: #C2C3C5;
                      &:hover {
                          color: var(--primary-color5);
                      }
                  }
                  &:last-child {
                      padding-bottom: 0;
                  }
              } 
          }
          @include tablet {
            margin-bottom: 40px
          }
      }
      &.widget-subcribe {
          margin-left: -14px;
          margin-right: 23px;
          .widget-title {
              margin-bottom: 20px;
          }
          #subscribe-form {
              position: relative;
              input {
                  width: 100%;
                  padding: 15px 46px 15px 23px;
                  font-size: 16px;
                  line-height: 26px;
                  color: #C2C3C5;
                  &::placeholder {
                      font-size: 16px;
                      color: #C2C3C5;
                  }
                  &:focus {
                      border-color: var(--primary-color3);
                  }
              }

              .tf-button {
                  right: 0;
                  position: absolute;
                  height: 100%;
                  padding: 0 35px;
                  background-color: var(--primary-color5);
                  color: #040B11;
                  &::before {
                      background-color: transparent;
                  }
                  &:hover {
                      border-color: #21E786;
                      background-color: transparent;
                      color: #fff;
                  }
              }
          }
          
      }
      .widget-title {
          font-size: 16px;
          line-height: 24px;
          color: #fff ;
          margin-bottom: 12px;
      }
  }
  .bottom {
      border-top: 1px solid #434343;
      padding: 13px 0 14px;
      @include flex(center,space-between);
      .social-item {
          @include flexitem;
          li {             
              margin-right: 45px;
              &:last-child {
                  margin-right: 0;
              }
              a {
                  font-size: 15px;
                  color: #fff;
                  &:hover {
                      color: var(--primary-color5);
                  }
              }
          }
      }
      .copy-right {
          color: #C2C3C5;
          font-size: 14px;
          line-height: 22px;
      }

      @include mobile {
        flex-wrap: wrap;
        justify-content: center !important;
      }
  }
  &.style-2 {
      text-align: center;
      .title {
          color: #fff;
          margin-bottom: 10px;
      }
      .content {
          font-family: 'Inter';
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: #C2C3C5;
          margin-bottom: 39px;
      }
      .group-btn {
          margin-left: 2px;
          margin-bottom: 40px;
          .tf-button {
              padding: 12px 24px;
              margin-right: 20px;

              @include w-402 {
                margin-right: 0;
                width: 70%;
              }
              i {
                  margin-right: 10px;
              }
              &:last-child {
                  margin-right: 0;
              }
              &.discord {
                  background-color: #5865F2;
                  color: #fff;
                  &::before {
                      background-color: #21E786 ;
                  }
              }
          }
      }
      form {
          max-width: 690px;
          margin: 0 auto;
          position: relative;
          margin-bottom: 31px;
          input {
              padding: 17px 24px 13px;
              color: #C2C3C5;
              &::placeholder {
                  color: #C2C3C5;
              }
          }
          .tf-button {
              position: absolute;
              right: 0;
              top: 0;
              height: 100%;
              padding: 0 36px;
          }
      }
      .social-item {
          @include flex(center,center);
          li {
              width: 50px;
              height: 50px;
              @include flex(center,center);
              margin-right: 20px;
              background-color: rgba(194, 195, 197, 0.15);
              @include transition3;
              &:last-child {
                  margin-right: 0;
              }
              a {
                  color: #fff;
              }
              &:hover {
                  background-color: #21E786;
                  cursor: pointer;
              }
          }
      }
      .footer-inner {
          padding-bottom: 79px;
      }
      .bottom-inner {
          border-top: 1px solid #434343;
      }
      .bottom {
          border: none;
          .content-left {
              @include flex(center,left);
              img {
                  margin-right: 61px;
              }

              @include mobile {
                flex-wrap: wrap
              }
          }
          .menu-bottom {
              @include flex(center,left);
              @include mobile {
                flex-wrap: wrap
              }
              li {
                  margin-right: 47px;
                  padding: 0;
                  a {
                      font-size: 14px;
                      line-height: 22px;
                      color: #C2C3C5; 
                      &:hover {
                          color: var(--primary-color5);
                      }
                  }
                  &:last-child {
                      margin-right: 0;
                  }
              }
          }
      }
  }
}


  #scroll-top {
    border-radius: 4px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    bottom: 23px;
    cursor: pointer;
    display: block;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    position: fixed;
    right: 14px;
    text-align: center;
    width: 50px;
    z-index: 999;
}
#scroll-top:after, #scroll-top:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all .3s ease;
  width: 100%;
}
#scroll-top:before {
  background: var(--primary-color5);
  z-index: -1;
}

#scroll-top::after {
  color: var(--background-body);
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-size: 18px;
  font-weight: 600;
}
